import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../shared/accordion/Accordion';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import PlaceholderTextBox from '../shared/placeholder/PlaceholderTextBox';
import { EditorProps, PlaceholderTarget } from './FormBuilderTypes';
import LanguageUtils from '../../utils/LanguageUtils';
import { supportedLanguages } from '../../types/Languages';

type PreviewPropertiesProps = EditorProps & {
  hideTitle?: boolean;
  hideDescirption?: boolean;
  enableFieldSync?: boolean;
  enableDocumentNumbering?: boolean;
  altTitle?: string;
  altDescription?: string;
};

const PreviewProperties: FC<PreviewPropertiesProps> = (props) => {
  const {
    action,
    patchAction,
    patchData,
    hideTitle,
    hideDescirption,
    form,
    patchForm,
    referencedForms,
    patchTranslations,
    enableFieldSync,
    enableDocumentNumbering,
    altTitle,
    altDescription,
  } = props;
  const { previewHidden, translations } = action || {};
  const { syncDescriptionToPreview, useDocumentNumbering } = action?.data || {};
  const previewQuestion = LanguageUtils.getTranslation('previewQuestion', action?.translations ?? {});
  const previewDescription = LanguageUtils.getTranslation('previewDescription', action?.translations ?? {});

  const { t } = useTranslation('form-builder');
  const [descriptionKey, setDescriptionKey] = useState(0);

  useEffect(() => {
    if (previewHidden === undefined) {
      patchAction({ previewHidden: false });
    }
  }, [patchAction, previewHidden]);

  useEffect(() => {
    if (!syncDescriptionToPreview) return;

    let changes = false;
    for (const lng of supportedLanguages.map((x) => x.id)) {
      if (action?.translations?.[lng]?.description !== action?.translations?.[lng]?.previewDescription) {
        changes = true;
        break;
      }
    }
    if (!changes) return;

    const translations = action?.translations ?? {};
    for (const lng of supportedLanguages.map((x) => x.id)) {
      const lngValue = action.translations?.[lng]?.description ?? '';
      if (!lngValue) continue;

      translations[lng] ??= {};
      translations[lng]!.previewDescription = lngValue;
    }
    patchTranslations(translations);
    setDescriptionKey((prev) => prev + 1);
  }, [action.translations, patchData, patchTranslations, previewDescription, syncDescriptionToPreview]);

  return (
    <div data-cy="preview-properties">
      <Accordion
        active={!!previewQuestion || !!previewDescription}
        title={<div className="font-medium">{t('action-properties.common.preview.title')}</div>}
      >
        <div className="mt-4 flex items-center justify-between">
          <div>{t('action-properties.common.preview.hide')}</div>
          <div>
            <Checkbox
              data-cy="hidden-display-mode"
              value={previewHidden ?? false}
              onChange={(newValue) => patchAction({ previewHidden: newValue })}
              slider
              sliderSize={SliderSize.S}
            />
          </div>
        </div>

        {enableDocumentNumbering && (
          <div className="mt-4 flex items-center justify-between">
            <div>{t('action-properties.common.preview.document-numbering')}</div>
            <div>
              <Checkbox
                data-cy="use-document-numbering-toggle"
                value={useDocumentNumbering ?? false}
                onChange={(newValue) => patchData({ useDocumentNumbering: newValue })}
                slider
                sliderSize={SliderSize.S}
              />
            </div>
          </div>
        )}

        {!hideDescirption && enableFieldSync && (
          <div className="mt-4 flex items-center justify-between">
            <div>{t('action-properties.common.preview.sync-description')}</div>
            <div>
              <Checkbox
                data-cy="sync-description-toggle"
                value={syncDescriptionToPreview ?? false}
                onChange={(newValue) => patchData({ syncDescriptionToPreview: newValue })}
                slider
                sliderSize={SliderSize.S}
              />
            </div>
          </div>
        )}

        {!hideTitle && (
          <div className="mt-4">
            <PlaceholderTextBox
              form={form}
              referencedForms={referencedForms}
              action={{ ...action, targetId: action.id, target: PlaceholderTarget.Action }}
              singleLine
              data-cy="display-mode-title"
              disabled={previewHidden}
              inputPlaceholder={altTitle || t('action-properties.common.preview.display-title')}
              label={altTitle || t('action-properties.common.preview.display-title')}
              onPlaceholdersChange={(placeholders) => {
                patchForm({ placeholders });
              }}
              enableLanguageToggle
              translationKey="previewQuestion"
              translations={translations ?? {}}
              onTranslationsChange={patchTranslations}
            />
          </div>
        )}
        {!hideDescirption && (
          <div className="mt-4">
            <PlaceholderTextBox
              key={descriptionKey}
              form={form}
              referencedForms={referencedForms}
              action={{ ...action, targetId: action.id, target: PlaceholderTarget.Action }}
              data-cy="display-mode-descripion"
              disabled={previewHidden || syncDescriptionToPreview}
              inputPlaceholder={altDescription || t('action-properties.common.preview.display-description')}
              label={altDescription || t('action-properties.common.preview.display-description')}
              onPlaceholdersChange={(placeholders) => {
                patchForm({ placeholders });
              }}
              enableRichText
              enableLanguageToggle
              translationKey="previewDescription"
              translations={translations ?? {}}
              onTranslationsChange={patchTranslations}
            />
          </div>
        )}
      </Accordion>
    </div>
  );
};

export default PreviewProperties;
